import "./index.css";
import Layout from "../components/layout";

import ImagePlayStoreBadge from "../images/google-play-badge.png";
import ImageAppStoreBadge from "../images/app-store-badge.svg";

import * as React from "react";

const APP_STORE_URL = "https://peekaphone.sng.link/Dhq0m/xaob/qhxu";
const PLAY_STORE_URL = "https://peekaphone.sng.link/Dhq0m/voer";

export default function IndexPage() {
    return (
        <Layout className="IndexPage">
            <title>{"Peek a Phone - Investigate Real-Life Mysteries"}</title>
            <h1 className="index-title">{"Peek a Phone"}</h1>
            <h2 className="index-subtitle">
                {"Investigate Real-Life Mysteries"}
            </h2>
            <div className="play-buttons">
                <a className="play-button google-play" href={PLAY_STORE_URL}>
                    <img
                        className="play-button-image"
                        alt="Get it on Google Play"
                        src={ImagePlayStoreBadge}
                    />
                </a>
                <a className="play-button app-store" href={APP_STORE_URL}>
                    <img
                        className="appstore-button-image"
                        alt="Download on the App Store"
                        src={ImageAppStoreBadge}
                    />
                </a>
            </div>
        </Layout>
    );
}
