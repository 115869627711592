import "./layout.css";
import Footer from "./Footer";

import * as React from "react";
import classnames from "classnames";

export default function Layout({ className, children }) {
    return (
        <main className={classnames("Layout", className)}>
            {children}
            <Footer />
        </main>
    );
}
