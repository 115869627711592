import "./Footer.css";

import React from "react";
import { Link } from "gatsby";

export default function Footer() {
	return (
		<div className="Footer">
			<ul className="Footer-list">
				<li>{`© ${new Date().getFullYear()} FaintLines, Inc.`}</li>
				<li>
					<Link to="/" className="Footer-link">
						{"Home"}
					</Link>
				</li>
				<li>
					<a
						href="https://faintlines.com/termsofuse"
						className="Footer-link"
					>
						{"Terms of Use"}
					</a>
				</li>
				<li>
					<a
						href="https://faintlines.com/privacy"
						className="Footer-link"
					>
						{"Privacy Policy"}
					</a>
				</li>
				<li>
					<a
						href="mailto:peekaphonegame@gmail.com"
						className="Footer-link"
					>
						{"Contact us"}
					</a>
				</li>
			</ul>
		</div>
	);
}
